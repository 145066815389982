import React from "react";

function Footer() {
  return (
    <footer className="py-5">
      <p className="text-center mb-0 text-sm">
        © 2021, McFarlaneTech. All Rights Reserved.
      </p>
    </footer>
  );
}

export default Footer;
