import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCheckCircle,
  faQuoteLeft,
  faQuoteRight,
  faEnvelope,
  faStar,
} from "@fortawesome/free-solid-svg-icons";

library.add(faCheckCircle, faQuoteLeft, faQuoteRight, faEnvelope, faStar);
