import PropTypes from "prop-types";
import React from "react";

import Header from "./header";
import Footer from "./footer";

import "../libs/font-awesome";

function Layout({ children, headerTransparent }) {
  return (
    <div className="text-gray-900 bg-white">
      <Header transparent={headerTransparent} />
      <main>{children}</main>
      <Footer />
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  headerTransparent: PropTypes.bool,
};

export default Layout;
