import { Link } from "gatsby";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function Header({ transparent }) {
  const [isExpanded, toggleExpansion] = useState(false);

  const onScroll = () => {
    const header = document.getElementById("header");

    if (!transparent) {
      return;
    }

    if (window.scrollY > 0) {
      header.classList.add("header-opaque");
      header.classList.remove("header-transparent");
    } else {
      header.classList.add("header-transparent");
      header.classList.remove("header-opaque");
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);

    // returned function will be called on component unmount
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <header
      id="header"
      className={`header ${
        transparent ? "header-transparent" : "header-opaque"
      }`}
    >
      <div className="container">
        <div className="flex items-center justify-between mx-auto">
          <Link to="/">
            <div className="flex items-center no-underline mb-0 leading-none">
              <span className="font-bold text-xl leading-none">
                <span className="font-bold">McFarlane</span>
                <span className="font-light">Tech</span>
              </span>{" "}
              <span className="mx-3 font-light leading-none block border-r h-4"></span>{" "}
              <div>
                <div className="text-xs uppercase leading-none font-light mr-3">
                  Bring your idea to life
                </div>
              </div>
            </div>
          </Link>

          <button
            className="flex top items-center block px-3 py-2 border border-white rounded lg:hidden"
            onClick={() => toggleExpansion(!isExpanded)}
          >
            <svg
              className="w-3 h-3 fill-current"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <title>Menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
            </svg>
          </button>

          <nav
            className={`${
              isExpanded ? `open` : `hidden`
            } lg:block lg:flex lg:items-center w-full lg:w-auto`}
          >
            <div className="m-auto uppercase text-sm">
              {[
                {
                  route: `/`,
                  title: `Home`,
                },
                {
                  route: `/#mission`,
                  title: `Our Mission`,
                },
                {
                  route: `/#process`,
                  title: `Our Process`,
                },
                {
                  route: `/#services`,
                  title: `Our Services`,
                },
                {
                  route: `/contact`,
                  title: `Contact us`,
                  icon: (
                    <FontAwesomeIcon
                      icon={["fas", "envelope"]}
                      className="mr-1 text-gray-400"
                    />
                  ),
                },
              ].map((link) => (
                <Link
                  className="block mt-4 no-underline lg:inline-block lg:mt-0 lg:ml-6 text-2xl lg:text-sm"
                  key={link.title}
                  to={link.route}
                  onClick={() => toggleExpansion(false)}
                >
                  <span className="hidden lg:inline-block">{link.icon}</span>
                  {link.title}
                </Link>
              ))}
            </div>
          </nav>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  transparent: PropTypes.bool,
};

export default Header;
